import React from "react"
import MobileLogo from "../../images/mobile.svg"
import { FormattedMessage } from "react-intl"

const AppDevThumbnail = ({ children, ...props }) => (
  <div {...props}>
    <div className="flex flex-col text-gray-700 items-center text-center px-4 py-2 m-2">
      <img src={MobileLogo} alt="" className="mx-auto h-16 mb-6" />
      <p className="uppercase font-light text-sm whitespace-no-wrap">
        <FormattedMessage id="generalServices.mobilePlatforms" />
      </p>
      <h4 className="mb-6 uppercase font-semibold text-xl leading-snug">
        <FormattedMessage id="generalServices.mobileApps" />
      </h4>
      {children}
    </div>
  </div>
)

export default AppDevThumbnail
