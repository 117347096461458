import React from "react"
import { FormattedMessage } from "react-intl"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"

const UsedTech = ({ title, AppDev, SoftDev, UiUx }) => {
  return (
    <div className="container mx-auto py-32 px-10 lg:px-32">
      <h3 className="text-center font-semibold text-2xl mb-12">
        <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
      </h3>
      <div className="md:flex md:flex-row justify-center">
        {AppDev && <AppDevThumbnail className="md:w-1/3" />}
        {SoftDev && <SoftDevThumbnail className="md:w-1/3" />}
        {UiUx && <UiUxThumbnail className="md:w-1/3" />}
      </div>
    </div>
  )
}

export default UsedTech
