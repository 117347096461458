import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import UsedTech from "../components/UsedTech"

import "../components/List.css"

import DesignCirclePartImage from "../images/mask@3x.png"
import EllipseImage from "../images/ellipse.svg"
import DesignCornerImage from "../images/rectangle-md.svg"
import DesignRectangle from "../images/greener-square.svg"
import SemiCircleImage from "../images/semi-circle.svg"
import DecorationCircle1Image from "../images/decoration-circle-1.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  challengeDescription,
  challengeImage,
  solutionDescription,
  solutionImage,
  solutionAdditionalInfo,
  solutionAdditionalInfoHighlight,
  resultDescription,
  solutionImage2,
  resultItems,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-12 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180 hidden md:block"
          style={{ left: "35%", top: "5px" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="bg-gray-200 relative overflow-hidden">
        <img
          src={EllipseImage}
          alt=""
          className="absolute w-1/2 top-0 -left-20 z-10"
        />
        <div className="container mx-auto py-20 px-16 lg:px-32">
          <div className="md:flex md:flex-row py-10 relative z-20">
            <div className="md:w-4/5 md:pr-0 mb-10 md:mb-0 items-center">
              <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            </div>
            <div className="md:w-4/5 text-gray-500 md:px-12">
              <p className="pt-10 md:pr-16">{challengeDescription}</p>
            </div>
            <img
              src={DesignCirclePartImage}
              alt=""
              className="absolute w-24 transform -rotate-90 top-0 right-0"
            />
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK*/}
      <div className="relative container mx-auto py-12 px-16 md:py-20 md:px-10 lg:px-32 overflow-hidden">
        <img
          src={SemiCircleImage}
          alt=""
          style={{ left: "25%", top: "-25%" }}
          className="absolute w-1/2 z-10"
        />
        <div className="md:flex flex-col">
          <div className="flex z-20">
            <img
              src={solutionImage.childImageSharp.fixed.src}
              alt=""
              className=""
            />
          </div>
          <div>
            <div className="flex flex-col">
              <p className="my-10 text-gray-500">{solutionDescription}</p>
              <div className="md:flex flex-row">
                <p className="md:w-1/2 md:mr-8 mb-10 md:mb-0 text-gray-500">
                  {solutionAdditionalInfo}
                </p>
                <p className="md:w-1/2 md:ml-8 text-gray-500">
                  {solutionAdditionalInfoHighlight}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="bg-gray-200 relative">
        <img
          src={DecorationCircle1Image}
          alt=""
          className="absolute w-48 transform rotate-12 -top-20 right-40 hidden md:block"
        />

        <div className="realative container mx-auto py-12 pt-16 px-16 md:px-10 lg:px-32">
          <div className="md:flex md:flex-row pb-2">
            <div className="md:w-1/3 text-gray-500">
              <div className="relative">
                <img
                  src={DesignCornerImage}
                  alt=""
                  className="absolute w-10 transform rotate-12 -top-2 md:top-12 -left-8"
                />
                <p className="pt-4 md:pt-20 mb-6">{resultDescription}</p>
                <ul className="list-default leading-8 pl-5">
                  {resultItems.map((item, i) => (
                    <li key={i} className="pl-4">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:w-3/5 md:pr-16 z-30">
              <img
                src={solutionImage2.childImageSharp.fixed.src}
                alt=""
                className="md:-mt-32 md:ml-40"
              />
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev UiUx />
    </div>
  )
}

const LatvijasNotars = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default LatvijasNotars

export const pageQuery = graphql`
  query LatvijasNotarsID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeDescription
        challengeImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionDescription
        solutionImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage2 {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionAdditionalInfo
        solutionAdditionalInfoHighlight
        resultDescription
        solutionHighlight
        resultItems
      }
    }
  }
`
